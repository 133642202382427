import { PropertyDetailAPIResponseInterface } from '../../property/interfaces/propertyDetail.interface';
import * as propertyScoreSchema from '../schema/propertyDetailDefaultScore.schema.json';

export function screen_guest_policies_score(propertyData: PropertyDetailAPIResponseInterface) {
  const PROPERTY_SCORE_SCHEMA = JSON.parse(JSON.stringify(propertyScoreSchema));
  const screenGuestPolicies = PROPERTY_SCORE_SCHEMA.screen_guest_policies;

  if (propertyData?.guest_policies?.smoking_policy?.value) {
      screenGuestPolicies.smoking_policy.calculated_score = 1;
  }
  if (propertyData?.guest_policies?.pets_policy?.value) {
      screenGuestPolicies.pets_policy.calculated_score = 1;
  }
  if (propertyData?.guest_policies?.extra_person_policy?.value) {
      screenGuestPolicies.extra_person_policy.calculated_score = 1;
  }
  if (propertyData?.financials?.pet_fee === 0 || propertyData?.financials?.pet_fee) {
      screenGuestPolicies.pet_fee.calculated_score = 1;
  }
  if (propertyData?.financials?.extra_person_fee === 0 || propertyData?.financials?.extra_person_fee) {
      screenGuestPolicies.extra_person_fee.calculated_score = 1;
  }
  if (propertyData?.guest_policies?.parties_events_policy?.value) {
      screenGuestPolicies.parties_events_policy.calculated_score = 1;
  }
  if (propertyData?.guest_policies?.refundable_damage_policy) {
      screenGuestPolicies.refundable_damage_policy.calculated_score = 1;
  }
  if (propertyData?.financials?.refundable_damage_deposit === 0 || propertyData?.financials?.refundable_damage_deposit) {
      screenGuestPolicies.refundable_damage_deposit.calculated_score = 1;
  }

  const { smoking_policy, pets_policy, pet_fee, extra_person_fee, extra_person_policy, parties_events_policy, refundable_damage_deposit, refundable_damage_policy } = screenGuestPolicies;

  screenGuestPolicies._total_score.calculated_score =
      smoking_policy.calculated_score +
      pets_policy.calculated_score +
      pet_fee.calculated_score +
      extra_person_fee.calculated_score +
      extra_person_policy.calculated_score +
      parties_events_policy.calculated_score +
      refundable_damage_deposit.calculated_score +
      refundable_damage_policy.calculated_score;

  return screenGuestPolicies;
}


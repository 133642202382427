import { PropertyDetailAPIResponseInterface } from '../../property/interfaces/propertyDetail.interface';
import * as propertyScoreSchema from '../schema/propertyDetailDefaultScore.schema.json';


export function screen_property_overview_score(propertyData: PropertyDetailAPIResponseInterface) {
  const PROPERTY_SCORE_SCHEMA: any = JSON.parse(JSON.stringify(propertyScoreSchema));
  const screenPropertyOverview = PROPERTY_SCORE_SCHEMA.screen_property_overview;

  if (propertyData?.property_description) {
      screenPropertyOverview.property_description.calculated_score = 1;
  }
  if (propertyData?.amenities?.length) {
      screenPropertyOverview.amenities.calculated_score = 1;
  }
  if (propertyData?.wifi_details?.wifi_name) {
      screenPropertyOverview.wifi_details.wifi_name.calculated_score = 1;
  }
  if (propertyData?.wifi_details?.wifi_password) {
      screenPropertyOverview.wifi_details.wifi_password.calculated_score = 1;
  }
  if (propertyData?.accommodation?.number_of_rooms === 0 || propertyData?.accommodation?.number_of_rooms) {
      screenPropertyOverview.accommodation.number_of_rooms.calculated_score = 1;
  }
  if (propertyData?.accommodation?.number_of_bathrooms === 0 || propertyData?.accommodation?.number_of_bathrooms) {
      screenPropertyOverview.accommodation.number_of_bathrooms.calculated_score = 1;
  }
  if (propertyData?.accommodation?.number_of_beds === 0 || propertyData?.accommodation?.number_of_beds) {
      screenPropertyOverview.accommodation.number_of_beds.calculated_score = 1;
  }
  if (propertyData?.accommodation?.number_of_kitchens === 0 || propertyData?.accommodation?.number_of_kitchens) {
      screenPropertyOverview.accommodation.number_of_kitchens.calculated_score = 1;
  }
  if (propertyData?.accommodation?.guest_capacity?.max_guests === 0 || propertyData?.accommodation?.guest_capacity?.max_guests) {
      screenPropertyOverview.accommodation.max_guest_capacity.calculated_score = 1;
  }

  screenPropertyOverview.wifi_details.calculated_score = 
      screenPropertyOverview.wifi_details.wifi_name.calculated_score + 
      screenPropertyOverview.wifi_details.wifi_password.calculated_score;

  screenPropertyOverview.accommodation.calculated_score =
      screenPropertyOverview.accommodation.number_of_rooms.calculated_score +
      screenPropertyOverview.accommodation.number_of_beds.calculated_score +
      screenPropertyOverview.accommodation.number_of_bathrooms.calculated_score +
      screenPropertyOverview.accommodation.number_of_kitchens.calculated_score +
      screenPropertyOverview.accommodation.max_guest_capacity.calculated_score;

  screenPropertyOverview._total_score.calculated_score = 
      screenPropertyOverview.accommodation.calculated_score + 
      screenPropertyOverview.wifi_details.calculated_score + 
      screenPropertyOverview.amenities.calculated_score + 
      screenPropertyOverview.property_description.calculated_score;

  return screenPropertyOverview;
}

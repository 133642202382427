import { PropertyDetailAPIResponseInterface } from '../../property/interfaces/propertyDetail.interface';
import * as propertyScoreSchema from '../schema/propertyDetailDefaultScore.schema.json';

export function screen_reservation_score(propertyData: PropertyDetailAPIResponseInterface) {
    const PROPERTY_SCORE_SCHEMA = JSON.parse(JSON.stringify(propertyScoreSchema));
    const screenReservation = PROPERTY_SCORE_SCHEMA.screen_reservation;

    if (
        (propertyData?.reservation?.checkin?.checkin_time_start?.hour && 
        propertyData?.reservation?.checkin?.checkin_time_start?.minutes && 
        propertyData?.reservation?.checkin?.checkin_time_start?.am_pm) || 
        (propertyData?.reservation?.checkin?.checkin_time_end?.hour && 
        propertyData?.reservation?.checkin?.checkin_time_end?.minutes && 
        propertyData?.reservation?.checkin?.checkin_time_end?.am_pm)
    ) {
        screenReservation.checkin_time.calculated_score = 1;
    }

    if (propertyData?.reservation?.checkin?.self_checkin?.availability !== null) {
        screenReservation.self_checkin.calculated_score = 1;
    }
    
    if (propertyData?.reservation?.checkin?.early_checkin?.option !== null && 
        propertyData?.reservation?.checkin?.early_checkin?.option !== "") {
        screenReservation.early_checkin.calculated_score = 1;
    }

    if (
        (propertyData?.reservation?.checkout?.checkout_time_start?.hour && 
        propertyData?.reservation?.checkout?.checkout_time_start?.minutes && 
        propertyData?.reservation?.checkout?.checkout_time_start?.am_pm) || 
        (propertyData?.reservation?.checkout?.checkout_time_end?.hour && 
        propertyData?.reservation?.checkout?.checkout_time_end?.minutes && 
        propertyData?.reservation?.checkout?.checkout_time_end?.am_pm)
    ) {
        screenReservation.checkout_time.calculated_score = 1;
    }

    if (propertyData?.reservation?.checkout?.self_checkout?.availability !== null) {
        screenReservation.self_checkout.calculated_score = 1;
    }

    const { checkin_time, self_checkin, early_checkin, checkout_time, self_checkout } = screenReservation;

    screenReservation._total_score.calculated_score = 
        checkin_time.calculated_score + 
        self_checkin.calculated_score + 
        early_checkin.calculated_score +
        checkout_time.calculated_score + 
        self_checkout.calculated_score;

    return screenReservation;
}


import { PropertyDetailAPIResponseInterface } from '../../property/interfaces/propertyDetail.interface';
import * as propertyScoreSchema from '../schema/propertyDetailDefaultScore.schema.json';

export function screen_contacts_score(propertyData: PropertyDetailAPIResponseInterface) {
    const PROPERTY_SCORE_SCHEMA = JSON.parse(JSON.stringify(propertyScoreSchema));
    const screenContacts = PROPERTY_SCORE_SCHEMA.screen_contacts;

    if (propertyData?.contacts?.personal_contact?.name) {
        screenContacts.name.calculated_score = 1;
    }
    if (propertyData?.contacts?.personal_contact?.contact_number) {
        screenContacts.contact_number.calculated_score = 1;
    }
    if (propertyData?.contacts?.personal_contact?.email) {
        screenContacts.email.calculated_score = 1;
    }
    if (propertyData?.contacts?.emergency_contacts?.police_helpline) {
        screenContacts.police_helpline.calculated_score = 1;
    }
    if (propertyData?.contacts?.emergency_contacts?.firebrigade_helpline) {
        screenContacts.firebrigade_helpline.calculated_score = 1;
    }
    if (propertyData?.contacts?.emergency_contacts?.ambulance_helpline) {
        screenContacts.ambulance_helpline.calculated_score = 1;
    }
    if (propertyData?.contacts?.emergency_contacts?.emergency_helpline) {
        screenContacts.emergency_helpline.calculated_score = 1;
    }

    const { 
        name, 
        contact_number, 
        email, 
        police_helpline, 
        firebrigade_helpline, 
        emergency_helpline, 
        ambulance_helpline 
    } = screenContacts;

    screenContacts._total_score.calculated_score = 
        name.calculated_score + 
        contact_number.calculated_score + 
        email.calculated_score + 
        police_helpline.calculated_score + 
        firebrigade_helpline.calculated_score + 
        emergency_helpline.calculated_score + 
        ambulance_helpline.calculated_score;

    return screenContacts;
}